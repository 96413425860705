import React from 'react';
import { Link } from 'gatsby';

export default ({ tags }) => {
  const sorted = tags[0].sort((a, b) => {
    return ('' + a).localeCompare(b);
  });
  const max = Math.max(...tags[1]);
  return (
    <>
      {sorted.map(tag => (
        <Link
          key={`/tag/${tag}`}
          to={`/tag/${tag.toLowerCase().replace(/\s+/g, '-')}`}
          className={`size-${Math.ceil(
            (tags[1][tags[0].indexOf(tag)] / max) * 10
          )}`}
        >
          {tag.toLowerCase()}
        </Link>
      ))}
    </>
  );
};

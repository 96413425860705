import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import RichText from './RichText';
import Img from 'gatsby-image';

const creator = userid => {
  switch (userid) {
    case 'rioksane':
      return 'Rikupekka Oksanen';
    case 'atsoukka':
      return 'Asko Soukka';
    case 'jptalask':
      return 'Jussi Talaskivi';
    default:
      return userid;
  }
};

const Post = ({ data, images = [], files = [] }) => (
  <>
    {data.image ? (
      <header className={'position-relative'}>
        <Img resolutions={data.image.childImageSharp.red} />
        <div
          className={'position-absolute bg-blue w-sm-75 p-4 mt-4'}
          style={{ bottom: 30 }}
        >
          <p className={'text-white'}>{creator(data.creators[0])}</p>
          <h1 className={'text-gold'}>{data.title}</h1>
          {data.description ? (
            <p className={'text-white'}>{data.description}</p>
          ) : null}
        </div>
      </header>
    ) : (
      <header className={'bg-blue w-sm-75 p-4 mb-4'}>
        <p className={'text-white'} style={{ marginTop: 80 }}>
          {creator(data.creators[0])}
        </p>
        <h1 className={'text-gold'}>{data.title}</h1>
        {data.description ? (
          <p className={'text-white'}>{data.description}</p>
        ) : null}
      </header>
    )}
    <article className={'container'} key={data._id}>
      <p>
        <strong>{data.effective}</strong>
      </p>
      {data.text ? (
        <RichText serialized={data.text.react} images={images} files={files} />
      ) : null}
    </article>
  </>
);

export const MenuItem = (data, duotone) => (
  <div
    key={data._id}
    className={data.image ? 'menu-item menu-image' : 'menu-item'}
  >
    <Link className={'text-gold'} to={data._path} title={data.title}>
      {data.image ? (
        duotone === '#f1563f' ? (
          <Img resolutions={data.image.childImageSharp.red} />
        ) : (
          <Img resolutions={data.image.childImageSharp.yellow} />
        )
      ) : null}
      <h2>
        <span>{data.title}</span>
      </h2>
    </Link>
  </div>
);

export default Post;

export const query = graphql`
  fragment Document on PloneClassic_page {
    id
    title
    description
    creators
    text {
      react
    }
    effective(formatString: "MMMM Do, YYYY")
    image {
      publicURL
      childImageSharp {
        red: fixed(
          height: 800
          duotone: { shadow: "#002957", highlight: "#f1563f", opacity: 0 }
        ) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    _path
  }
`;
